import { useEffect, useState } from "react";
import { getAllMediaFromPostWithResoultion, getAllSubpagesByParentId, getPage } from "../WordPressConnectionHelper";
import { useLocation } from "react-router-dom";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import { IconMenu2 } from "@tabler/icons-react";
import PageMenuItem from "../components/PageMenuItem";

const DynamicContentPage = () => {

    const location = useLocation();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [media, setMedia] = useState(null);
    const [index, setIndex] = useState(-1);

    const [menuVisible, setMenuVisible] = useState(false);
    const [subpages, setSubpages] = useState([]);

    const loadPage = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
        const fullPageName = window.location.pathname.split("/");
        const mainPageName = fullPageName[1];
        getPage(mainPageName)
            .then((mainPageData) => {
                if (mainPageData[0]) {
                    const pageID = mainPageData[0].id;
                    setTitle(mainPageData[0].title.rendered);
                    let actualPageToRender;
                    getAllSubpagesByParentId(pageID).then((subpagesData) => {
                        if (subpagesData.length > 0) {
                            if (fullPageName[2]) {
                                const findSubpage = subpagesData.find((item) => item.slug === fullPageName[2]);
                                if (findSubpage) {
                                    actualPageToRender = findSubpage;
                                }
                            } else if (fullPageName.length === 2) {
                                actualPageToRender = subpagesData[0];
                            }
                            setSubpages(subpagesData.map((item) => {
                                return <PageMenuItem key={item.id} label={item.title.rendered} slug={item.slug} parentSlug={mainPageName} />
                            }));
                        } else {
                            setSubpages([]);
                            actualPageToRender = mainPageData[0];
                        }

                        setContent(actualPageToRender.content.rendered);

                        getAllMediaFromPostWithResoultion(actualPageToRender.id).then((data) => {
                            if (data && data.length > 0) {
                                setMedia(data);
                            }
                        })
                    });


                } else {
                    //TO-DO
                }
            })
    }

    const toggleMenuVisible = () => {
        setMenuVisible(!menuVisible);
    }


    useEffect(() => {
        setMedia(null);
        loadPage();

    }, [location]);

    return (
        <div>
            <div className="subpages-main-div">
                <div className={`highContrastBackground subpages-content-div ${window.location.pathname.split("/").pop() == 'dzwonki' ? 'text-center' : ''}`}>
                    <div className="flex justify-between !mb-7 lg:!mb-10 relative">
                        <p className="text-3xl font-semibold">{title}</p>
                        {subpages.length > 0 &&
                            <div className="flex flex-col" onClick={() => toggleMenuVisible()}>
                                <div className="highContrastDiv self-end flex gap-2.5 bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition w-fit rounded-lg px-3 py-2.5 items-center shadow-md cursor-pointer">
                                    <IconMenu2 size="20" />
                                    <p>Menu</p>
                                </div>
                                <div className={`menuTransition flex flex-col absolute top-14 h-fit w-fit self-end min-w-72 overflow-hidden ${menuVisible ? 'max-h-96' : 'max-h-0 !p-0 border-none top-0'}`}>
                                    <div className={`highContrastBackground px-1.5 flex flex-col gap-2 py-1.5 bg-white/70 dark:bg-gray-800/60 rounded-lg shadow-lg backdrop-blur-sm dark:backdrop-blur-md border dark:border-none `}>
                                        {subpages}
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                    {<div className="dont-show-images paragraph-margin iframe-content" dangerouslySetInnerHTML={{ __html: content }} />}

                    {media &&
                        <div className="round-images border dark:border-gray-800 rounded-lg px-5 py-4">
                            <PhotoAlbum
                                layout="columns"
                                photos={media}
                                onClick={({ index: current }) => setIndex(current)}
                            />
                            <Lightbox
                                index={index}
                                slides={media}
                                open={index >= 0}
                                close={() => setIndex(-1)}
                                plugins={[Counter, Zoom, Slideshow]}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default DynamicContentPage;