import axios from "axios";

let endpoint = "https://zse.krakow.pl";
//let endpoint = "http://192.168.50.9/zse";

const getFullNavigation = async () => {
    let data;
    await axios.get(`${endpoint}/wordpress/wp-json/menus/v1/menus/menu-glowne`)
        .then((response) => {
            data = response.data;
        })
        .catch((error) => {
            console.log(error);
        })

    return data;
}

const getPage = async (slug) => {
    let data;
    await axios.get(`${endpoint}/wordpress/wp-json/wp/v2/pages?slug=${slug}`)
        .then((response) => {
            data = response.data;
        })
        .catch((error) => {
            console.log(error);
        });

    return data;
}

const getLatestPostsLimited = async (limit) => {
    let data;
    await axios.get(`${endpoint}/wordpress/wp-json/wp/v2/posts?per_page=${limit}`)
        .then((response) => {
            data = response.data;
        })
        .catch((error) => {
            console.log(error);
        });

    return data;
}

const getPost = async (id) => {
    let data;
    await axios.get(`${endpoint}/wordpress/wp-json/wp/v2/posts/${id}`)
        .then((response) => {
            data = response.data;
        })
        .catch((error) => {
            console.log(error);
        });

    return data;
}

const getMediaById = async (id) => {
    let data;
    await axios.get(`${endpoint}/wordpress/wp-json/wp/v2/media/${id}`)
        .then((response) => {
            data = {
                url: response.data.guid.rendered,
                alt: response.data.alt_text
            }
        })
        .catch((error) => {
            console.log(error);
        })

    return data;
}

const getPostsByPageNumber = async (number) => {
    let data;
    let totalPagesNumber;
    await axios.get(`${endpoint}/wordpress/wp-json/wp/v2/posts?page=${number}`)
        .then((response) => {
            data = response.data;
            totalPagesNumber = response.headers.toJSON()["x-wp-totalpages"];
        })
        .catch((error) => {
            console.log(error);
        });

    return [data, totalPagesNumber];
}

const getAllMediaFromPage = async (id) => {
    let mediaList = [];
    await axios.get(`${endpoint}/wordpress/wp-json/wp/v2/media?parent=${id}&per_page=100`)
        .then((response) => {
            mediaList = response.data;
        })
        .catch((error) => {
            console.log(error);
        });

    return mediaList;
}

const getAllMediaFromPostWithResoultion = async (id) => {
    let mediaList = [];
    await axios.get(`${endpoint}/wordpress/wp-json/wp/v2/media?parent=${id}&per_page=100`)
        .then((response) => {
            response.data.map((single) => {
                let sizes = single.media_details.sizes;
                if (single.media_type === "image") {
                    if (sizes.large) {
                        mediaList.push({
                            src: single.media_details.sizes.large.source_url,
                            width: single.media_details.sizes.large.width,
                            height: single.media_details.sizes.large.height,
                            alt: single.alt_text
                        });
                    } else if (sizes.medium_large) {
                        mediaList.push({
                            src: single.media_details.sizes.medium_large.source_url,
                            width: single.media_details.sizes.medium_large.width,
                            height: single.media_details.sizes.medium_large.height,
                            alt: single.alt_text
                        });
                    }
                    else {
                        mediaList.push({
                            src: single.source_url,
                            width: single.media_details.width,
                            height: single.media_details.height,
                            alt: single.alt_text
                        });
                    }
                }
            });
        })
        .catch((error) => {
            console.log(error);
        });

    return mediaList;
}

const getSearchResults = async (query) => {
    let results;
    await axios.get(`${endpoint}/wordpress/wp-json/wp/v2/search?search=${query}`)
        .then((response) => {
            results = response.data;
        })
        .catch((error) => {
            console.log(error);
        });

    return results;
}

const getPostsByCategoryByPageNumber = async (category, number) => {
    let data;
    let totalPagesNumber;
    await axios.get(`${endpoint}/wordpress/wp-json/wp/v2/posts?categories=${category}&page=${number}`)
        .then((response) => {
            data = response.data;
            totalPagesNumber = response.headers.toJSON()["x-wp-totalpages"];
        })
        .catch((error) => {
            console.log(error);
        });

    return [data, totalPagesNumber];
}

const getAllSubpagesByParentId = async(parentID) => {
    let data;
    await axios.get(`${endpoint}/wordpress/wp-json/wp/v2/pages?parent=${parentID}&order=asc`)
        .then((response) => {
            data = response.data;
        })
        .catch((error) => {
            console.log(error);
        });

    return data;
}

const getStickyPosts = async (limit) => {
    let data;
    await axios.get(`${endpoint}/wordpress/wp-json/wp/v2/posts?sticky=true`)
        .then((response) => {
            data = response.data;
        })
        .catch((error) => {
            console.log(error);
        });

    return data;
}

export { getFullNavigation, getPage, getLatestPostsLimited, getPost, getMediaById, getPostsByPageNumber, getAllMediaFromPostWithResoultion, getSearchResults, getPostsByCategoryByPageNumber, getAllMediaFromPage, getAllSubpagesByParentId, getStickyPosts }