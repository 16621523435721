import { IconChevronRight, IconNews, IconPin } from "@tabler/icons-react";
import Article from "./Article";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getLatestPostsLimited, getStickyPosts } from "../WordPressConnectionHelper";
import ArticleSkeleton from "./ArticleSkeleton";

const MainArticles = () => {

    const [postsList, setPostsList] = useState([]);
    const [pinnedPostsList, setPinnedPostsList] = useState([]);

    const getLatestArticles = () => {
        getLatestPostsLimited(7).then((data) => {
            if (data) {
                let standard = [];
                data.forEach((single) => {
                    let element = <Article key={single.id} id={single.id} title={single.title.rendered} mainImage={single.featured_media} creationDate={single.date} content={single.excerpt.rendered} />;
                    if (!single.sticky) {
                        standard.push(element);
                    }
                });

                setPostsList(standard);
            }
        });

        getStickyPosts().then((data) => {
            if (data) {
                setPinnedPostsList(data.map((single) =>
                    <Article key={single.id} id={single.id} title={single.title.rendered} mainImage={single.featured_media} creationDate={single.date} content={single.excerpt.rendered} />
                ));
            }
        })
    }

    useEffect(() => {
        getLatestArticles();
    }, []);

    return (
        <div>
            <div
                className="flex flex-col md:min-w-[400px] md:order-1 mt-1 mb-4 md:mt-5 mx-3 order-1 max-w-4xl flex-grow border-dashed md:border-solid md:rounded-lg">
                <p className="mt-9 md:mt-0 text-center md:text-left text-3xl font-bold bg-clip-text bg-gradient-to-r text-transparent from-blue-500 to-blue-900 highContrastBackground">Aktualności</p>
                {pinnedPostsList.length > 0 &&
                    <div className="mt-10 flex flex-col space-y-7 w-full flex-grow md:min-w-[400px] xl:min-w-[750px]">
                        <div className="pl-5 md:pl-0 flex gap-1.5 items-center">
                            <IconPin size="20" />
                            <p className="text-sm font-semibold">Przypięte</p>
                        </div>
                        {pinnedPostsList}
                    </div>
                }
                <div className="mt-7 flex flex-col space-y-7 w-full flex-grow md:min-w-[400px] xl:min-w-[750px]">
                    {pinnedPostsList.length > 0 &&
                        <div className="pl-5 md:pl-0 flex gap-1.5 items-center">
                            <IconNews size="20" />
                            <p className="text-sm font-semibold">Najnowsze</p>
                        </div>
                    }
                    {postsList.length === 0 &&
                        <div className="animate-pulse flex flex-col flex-grow md:min-w-[400px] xl:min-w-[750px] max-w-4xl space-y-7 w-full">
                            <ArticleSkeleton />
                            <ArticleSkeleton />
                            <ArticleSkeleton />
                            <ArticleSkeleton />
                            <ArticleSkeleton />
                        </div>
                    }
                    {postsList}
                    <Link to={`archiwum`}>
                        <div
                            className="highContrastBackground flex items-center text-sm justify-center space-x-1.5 rounded-md dark:bg-gray-900 dark:text-white border border-slate-200 dark:border-gray-800 hover:underline transition cursor-pointer p-3">
                            <p>Zobacz pozostałe aktualności</p>
                            <IconChevronRight className="w-4 h-4" />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default MainArticles;