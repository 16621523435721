const ArticleSkeleton = () => {
    return (
        <div
            className="highContrastBackground max-w-3xl min-w-max flex-shrink bg-slate-50 dark:bg-gray-800 rounded-lg p-5 flex flex-col space-y-7 border border-slate-200 dark:border-gray-700">
            <div className="rounded-lg h-96 min-w-max flex-grow flex-shrink bg-slate-100 dark:bg-gray-700"></div>
            <div className="flex flex-col max-w-3xl w-full flex-grow space-y-7">
                <div className="flex flex-col space-y-2">
                    <div className="h-9 w-full bg-slate-100 dark:bg-gray-700 rounded-full"></div>
                    <div className="h-9 w-3/4 bg-slate-100 dark:bg-gray-700 rounded-full"></div>
                </div>
                <div className="flex flex-col flex-grow space-y-2">
                    <div className="h-6 w-full bg-slate-100 dark:bg-gray-700 rounded-full"></div>
                    <div className="h-6 w-11/12 bg-slate-100 dark:bg-gray-700 rounded-full"></div>
                    <div className="h-6 w-9/12 bg-slate-100 dark:bg-gray-700 rounded-full"></div>
                </div>
            </div>
            <div
                className="highContrastBackground w-32 h-9 self-end rounded-md dark:bg-gray-700 px-3 py-2">
            </div>
        </div>

    );
}

export default ArticleSkeleton;