import { IconChevronRight } from "@tabler/icons-react";
import { useRouteError } from "react-router-dom";

const ErrorPage = () => {
    const error = useRouteError();
    console.error(error);
    return (
        <div className="!text-white flex flex-col p-7 gap-10 items-center justify-evenly w-screen h-screen bg-gradient-to-bl from-blue-950 to-slate-950">
            <p className="text-5xl font-semibold text-center">Wystąpił błąd</p>
            <div className="flex flex-col items-center gap-10 text-center">
                <p className="text-xl">Nie można załadować żądanej strony z powodu błędu</p>
                <p className="text-4xl font-semibold">{error.status} {error.statusText}</p>
                <p className="text-sm text-white/50">{error.error.message}</p>
            </div>
            <a href="https://zse.krakow.pl">
                <div className="flex items-center gap-5 py-3 px-5 bg-blue-950/70 rounded-lg hover:bg-blue-900/70 transition-all">
                    <img className="w-9 h-9" src="/assets/zse-light.png" alt="Logo ZSE" />
                    <p>Powrót na główną stronę ZSE</p>
                    <IconChevronRight />
                </div>
            </a>
        </div>
    );
}

export default ErrorPage;