import { IconChevronRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";

const PageMenuItem = (props) => {
    return (
        <div>
            <Link className="!text-black dark:!text-white !decoration-current max-w-fit" to={`/${props.parentSlug}/${props.slug}`}>
                <div className="highContrastDiv flex gap-1.5 py-2.5 px-3 bg-gray-100/80 dark:bg-gray-700/50 hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer transition items-center rounded-lg">
                    <IconChevronRight size="20" />
                    <p>{props.label}</p>
                </div>
            </Link>
        </div>


    );
}

export default PageMenuItem;