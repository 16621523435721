import { useEffect } from "react";
import { useState } from "react";
import { getAllMediaFromPostWithResoultion, getPost } from "../WordPressConnectionHelper";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";

const SinglePostPage = () => {    
    const [postContent, setPostContent] = useState(null);
    const [postTitle, setPostTitle] = useState(null);
    const [postDate, setPostDate] = useState(null);
    const [media, setMedia] = useState(null);
    const [index, setIndex] = useState(-1);

    const getFullPost = (id) => {
        window.scrollTo({ top: 0, behavior: 'instant' });
        getPost(id)
            .then((data) => {
                setPostTitle(data.title.rendered);

                const d = new Date(data.date);
                setPostDate(d.toLocaleDateString());
                setPostContent(data.content.rendered);

                getAllMediaFromPostWithResoultion(data.id).then((data) => {
                    if (data && data.length > 0) {
                        setMedia(data);
                    }
                })
            })
    }

    useEffect(() => {
        const postID = window.location.pathname.split("/").pop();
        getFullPost(postID);
    }, []);

    return (
        <div>
            <div className="subpages-main-div">
                <div className="highContrastBackground subpages-content-div">
                    <p className="text-3xl font-semibold mb-4 lg:!mb-10;">{postTitle}</p>
                    <p className="text-sm">{postDate}</p>

                    {<div className="dont-show-images paragraph-margin my-5 iframe-content" dangerouslySetInnerHTML={{ __html: postContent }} />}

                    {media &&
                        <div className="round-images border dark:border-gray-800 rounded-lg px-5 py-4">
                            <PhotoAlbum
                                layout="columns"
                                photos={media}
                                onClick={({ index: current }) => setIndex(current)}
                            />
                            <Lightbox
                                index={index}
                                slides={media}
                                open={index >= 0}
                                close={() => setIndex(-1)}
                                plugins={[Counter, Zoom, Slideshow]}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SinglePostPage;